import React, { useEffect, useRef, useState } from 'react';
import { gsap } from 'gsap';
import liliana from './img/liliana.png';
import WebFont from 'webfontloader';

const useHeroAnimation = () => {
  const animate = (elements) => {
    const { liliana, craftingDigital, dreams, subHeading, scrollPrompt } = elements;

    if (!liliana || !craftingDigital || !dreams || !subHeading || !scrollPrompt) {
      return;
    }

    const tl = gsap.timeline();

    // Set initial states
    gsap.set([craftingDigital, dreams, subHeading, scrollPrompt], {
      y: 200,
      opacity: 0
    });

    // Animate Liliana image (continuous rotation)
    gsap.to(liliana, {
      duration: 25,
      rotation: '+=360',
      repeat: -1,
      ease: 'none',
      transformOrigin: '50% 50%',
    });

    // Animation sequence with delay for "crafting digital"
    tl.to(craftingDigital, {
      opacity: 1,
      y: 0,
      duration: 1,
      ease: 'power2.out',
      delay: 0.5 // Add a 0.5 second delay before this animation starts
    })
      .to(dreams, {
        opacity: 1,
        y: 0,
        duration: 0.8,
        ease: 'power2.out',
        onStart: () => {
          dreams.classList.add('dreams-glow');
          gsap.to(dreams, {
            '--glow-intensity': 3,
            duration: 2,
            ease: 'power1.inOut'
          });
        }
      }, '+=0.2')
      .to({}, { duration: 1 })
      .to(subHeading, {
        y: 0,
        opacity: 1,
        duration: 2,
        ease: 'power2.out',
        onStart: () => {
          gsap.to(dreams, {
            '--glow-intensity': 1,
            duration: 0.3,
            ease: 'power2.inOut',
            onComplete: () => {
              dreams.classList.remove('dreams-glow');
            }
          });
        }
      })
      .to(scrollPrompt, {
        y: 0,
        opacity: 0.1,
        duration: 8,
        ease: 'power2.out'
      }, '-=0.4');

    // Scroll prompt animation
    gsap.to(scrollPrompt, {
      y: '+=10',
      yoyo: true,
      repeat: -1,
      duration: 1,
      opacity: 0.1,
      ease: 'power1.inOut',
      delay: 9
    });

    return () => {
      tl.kill();
    };
  };

  return animate;
};

const Hero = () => {
  const lilianaRef = useRef(null);
  const heroSectionRef = useRef(null);
  const craftingDigitalRef = useRef(null);
  const dreamsRef = useRef(null);
  const subHeadingRef = useRef(null);
  const scrollPromptRef = useRef(null);

  const [refsReady, setRefsReady] = useState(false);
  const [fontsLoaded, setFontsLoaded] = useState(false);
  const animate = useHeroAnimation();

  useEffect(() => {
    // Load Google Fonts
    WebFont.load({
      google: {
        families: ['poppins'] // Replace with your actual Google Font name
      },
      active: () => setFontsLoaded(true),
      inactive: () => setFontsLoaded(true) // Set to true even if fonts fail to load, to prevent blocking
    });
  }, []);

  useEffect(() => {
    if (lilianaRef.current && craftingDigitalRef.current && dreamsRef.current &&
      subHeadingRef.current && scrollPromptRef.current) {
      setRefsReady(true);
    }
  }, [lilianaRef, craftingDigitalRef, dreamsRef, subHeadingRef, scrollPromptRef]);

  useEffect(() => {
    if (refsReady && fontsLoaded) {
      animate({
        liliana: lilianaRef.current,
        craftingDigital: craftingDigitalRef.current,
        dreams: dreamsRef.current,
        subHeading: subHeadingRef.current,
        scrollPrompt: scrollPromptRef.current
      });
    }
  }, [refsReady, fontsLoaded, animate]);

  return (

    <section id='hero' ref={heroSectionRef}>
      <div className='liliana-container'>
        <img
          ref={lilianaRef}
          src={liliana}
          alt='Liliana'
          className='liliana-svg'
        />
      </div>
      <div className='heading' style={{ visibility: fontsLoaded ? 'visible' : 'hidden' }}>
        <h3>
          <span ref={craftingDigitalRef}>crafting digital{' '}</span>
          <span ref={dreamsRef} className="dreams">dreams</span>
        </h3>
      </div>
      <p ref={subHeadingRef} className='sub-heading' style={{ visibility: fontsLoaded ? 'visible' : 'hidden' }}>
        ✨into reality ✨
      </p>
      <div ref={scrollPromptRef} className='scroll-prompt'>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" width="52" height="52">
          <path d="M16 3C12.15 3 9 6.15 9 10v12c0 3.85 3.15 7 7 7s7-3.15 7-7V10c0-3.85-3.15-7-7-7zm0 2c2.77 0 5 2.23 5 5v12c0 2.77-2.23 5-5 5s-5-2.23-5-5V10c0-2.77 2.23-5 5-5zm0 4c-.55 0-1 .45-1 1v3c0 .55.45 1 1 1s1-.45 1-1v-3c0-.55-.45-1-1-1z"/>
        </svg>
      </div>
      <div className='custom-shape-divider-bottom-1708512159'>
        <svg
          data-name='Layer 1'
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 1200 120'
          preserveAspectRatio='none'
        >
          <path
            d='M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z'
            className='shape-fill-black'
          ></path>
        </svg>
      </div>
    </section>
  );
};

export default Hero;