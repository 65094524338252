import React from 'react';

const BiographyText = () => {
  return (
    <div className="col">
      <p>
        A software engineer whose journey began amidst the vibrant
        landscapes of Portugal and has flourished in Melbourne's dynamic
        pulse, Australia. Tinkering in the digital realm from an early age,
        I formally embarked on my web development voyage in 2014. This
        journey has been a bridge between continents and cultures, enabling
        me to craft web experiences that are not only innovative but
        ethically grounded.
      </p>
      <p>
        When I'm not coding a greener future into the web, I'm likely
        embracing the great outdoors, capturing life through my camera, or
        at my sewing table, crafting heartfelt creations for friends and
        family. Each hobby weaves into the fabric of my life, reflecting the
        diversity of my interests and the depth of my creativity.
      </p>
      <p>
        With 8 years of agency experience, I merge Portuguese warmth with
        Aussie ingenuity, crafting web solutions that resonate with hearts
        worldwide and spur action for a better tomorrow. Join me on this
        journey, as we transform digital dreams into planet-friendly
        realities.
      </p>

      <div className="availability-status">
        <span className="status-dot"></span>
        <span className="status-text">Available for work</span>
      </div>
    </div>
  );
};

export default BiographyText;