import React, { useRef, useEffect } from 'react';
import { gsap } from 'gsap';

const ProfileCard = ({ imageUrl }) => {
  const cardRef = useRef(null);

  const map = (value, inMin, inMax, outMin, outMax) => {
    return ((value - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin;
  };

  useEffect(() => {
    const onMouseMove = event => {
      const { offsetX, offsetY, target } = event;
      const { width, height } = target.getBoundingClientRect();

      const rotateY = map(offsetX, 0, width, -25, 25);
      const rotateX = map(offsetY, 0, height, 25, -25);
      const brightness = map(offsetY, 0, height, 1.5, 0.5);

      gsap.to(cardRef.current, {
        rotationY: rotateY,
        rotationX: rotateX,
        ease: 'ease-out',
        transformPerspective: 600,
        filter: `brightness(${brightness})`,
      });
    };

    const onMouseEnter = () => {
      gsap.to(cardRef.current, {
        scale: 1.3,
        duration: 0.5,
        ease: 'power1.out',
      });
    };

    const onMouseLeave = () => {
      gsap.to(cardRef.current, {
        rotationY: 0,
        rotationX: 0,
        scale: 1,
        ease: 'power1.out',
        transformPerspective: 800,
        filter: 'brightness(1)',
      });
    };

    const cardElement = cardRef.current;
    if (cardElement) {
      cardElement.addEventListener('mousemove', onMouseMove);
      cardElement.addEventListener('mouseenter', onMouseEnter);
      cardElement.addEventListener('mouseleave', onMouseLeave);
    }

    return () => {
      if (cardElement) {
        cardElement.removeEventListener('mousemove', onMouseMove);
        cardElement.removeEventListener('mouseenter', onMouseEnter);
        cardElement.removeEventListener('mouseleave', onMouseLeave);
      }
    };
  }, []);

  return (
    <div className="col">
      <div className="card3d" ref={cardRef}>
        <img
          src={imageUrl}
          className='card3d-img'
          alt='Liliana Summers Web Developer'
          onContextMenu={event => event.preventDefault()}
          draggable='false'
        />
      </div>
    </div>
  );
};

export default ProfileCard;