import React, { useState, useEffect } from 'react';
import {
  FaPhp,
  FaWordpress,
  FaDrupal,
  FaReact,
  FaJs,
  FaDocker,
  FaJira,
  FaConfluence,
  FaUsers,
  FaLightbulb,
  FaClock,
  FaChalkboardTeacher,
  FaTerminal,
  FaDatabase,
  FaCode,
  FaBug,
  FaRocket,
  FaComments,
  FaProjectDiagram,
} from 'react-icons/fa';
import { SiNextdotjs, SiPostman, SiVercel, SiFigma } from 'react-icons/si';

const skillsData = {
  languages: [
    { name: 'PHP', icon: FaPhp, color: 'rgba(148,187,233,1)', percentage: 90 },
    {
      name: 'WordPress',
      icon: FaWordpress,
      color: 'rgba(148,187,233,1)',
      percentage: 85,
    },
    {
      name: 'SilverStripe',
      icon: FaPhp,
      color: 'rgba(148,187,233,1)',
      percentage: 90,
    },
    {
      name: 'Drupal',
      icon: FaDrupal,
      color: 'rgba(148,187,233,1)',
      percentage: 75,
    },
    {
      name: 'MySQL - MariaDB - Supabase',
      icon: FaDatabase,
      color: 'rgba(148,187,233,1)',
      percentage: 87,
    },
    {
      name: 'HTML + CSS',
      icon: FaReact,
      color: 'rgba(148,187,233,1)',
      percentage: 99,
    },
    {
      name: 'React',
      icon: FaReact,
      color: 'rgba(148,187,233,1)',
      percentage: 88,
    },
    {
      name: 'Next.js',
      icon: SiNextdotjs,
      color: 'rgba(148,187,233,1)',
      percentage: 82,
    },
    {
      name: 'JavaScript',
      icon: FaJs,
      color: 'rgba(148,187,233,1)',
      percentage: 92,
    },
    {
      name: 'APIs',
      icon: FaCode,
      color: 'rgba(148,187,233,1)',
      percentage: 87,
    },
  ],
  tools: [
    { name: 'Docker', icon: FaDocker, color: '#eeaeca', percentage: 90 },
    { name: 'Figma', icon: SiFigma, color: '#eeaeca', percentage: 86 },
    { name: 'Jira', icon: FaJira, color: '#eeaeca', percentage: 85 },
    {
      name: 'Confluence',
      icon: FaConfluence,
      color: '#eeaeca',
      percentage: 80,
    },
    {
      name: 'VS Code + PHP Storm',
      icon: FaCode,
      color: '#eeaeca',
      percentage: 98,
    },
    { name: 'CLI', icon: FaTerminal, color: '#eeaeca', percentage: 90 },
    { name: 'Vercel', icon: SiVercel, color: '#eeaeca', percentage: 75 },
    { name: 'Postman', icon: SiPostman, color: '#eeaeca', percentage: 74 },
  ],
  softSkills: [
    { name: 'Collaboration', icon: FaUsers, color: '#f2cd51', percentage: 95 },
    {
      name: 'Problem Solving',
      icon: FaLightbulb,
      color: '#f2cd51',
      percentage: 99,
    },
    {
      name: 'Time Management',
      icon: FaClock,
      color: '#f2cd51',
      percentage: 88,
    },
    {
      name: 'Mentoring',
      icon: FaChalkboardTeacher,
      color: '#f2cd51',
      percentage: 45,
    },
    {
      name: 'Communication',
      icon: FaComments,
      color: '#f2cd51',
      percentage: 90,
    },
    { name: 'Adaptability', icon: FaRocket, color: '#f2cd51', percentage: 87 },
    {
      name: 'Attention to Detail',
      icon: FaBug,
      color: '#f2cd51',
      percentage: 99,
    },
    {
      name: 'Project Management',
      icon: FaProjectDiagram,
      color: '#f2cd51',
      percentage: 75,
    },
  ],
};

const SkillItem = ({ skill, isVisible }) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (isVisible) {
      const timer = setTimeout(() => setProgress(skill.percentage), 100);
      return () => clearTimeout(timer);
    }
  }, [isVisible, skill.percentage]);

  return (
    <div style={{ marginBottom: '1rem' }} className='skills-container'>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          marginBottom: '0.5rem',
        }}
      >
        <skill.icon
          size={20}
          style={{ marginRight: '0.5rem', color: skill.color }}
        />
        <span style={{ fontWeight: 'bold', marginRight: '0.5rem' }}>
          {skill.name}
        </span>
      </div>
      <div
        style={{
          backgroundColor: '#e7e7dd',
          borderRadius: '4px',
          height: '10px',
        }}
      >
        <div
          style={{
            width: `${progress}%`,
            backgroundColor: skill.color,
            height: '100%',
            borderRadius: '4px',
            transition: 'width 1s ease-out',
          }}
        />
      </div>
    </div>
  );
};

const SkillColumn = ({ skills, visibleSkills }) => {
  return (
    <div>
      {skills.map((skill, index) => (
        <div key={skill.name} id={`skill-${skill.name}`} className='skill-item'>
          <SkillItem
            skill={skill}
            isVisible={visibleSkills.includes(`skill-${skill.name}`)}
          />
        </div>
      ))}
    </div>
  );
};

const SplitSkillColumn = ({ title, skills }) => {
  const [visibleSkills, setVisibleSkills] = useState([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            setVisibleSkills(prev => [...prev, entry.target.id]);
          }
        });
      },
      { threshold: 0.1 }
    );

    document
      .querySelectorAll('.skill-item')
      .forEach(el => observer.observe(el));

    return () => observer.disconnect();
  }, []);

  const midpoint = Math.ceil(skills.length / 2);
  const leftColumnSkills = skills.slice(0, midpoint);
  const rightColumnSkills = skills.slice(midpoint);

  return (
    <div className='skill-category'>
      <h3>{title}</h3>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ flex: 1, paddingRight: '1rem' }}>
          <SkillColumn
            skills={leftColumnSkills}
            visibleSkills={visibleSkills}
          />
        </div>
        <div style={{ flex: 1, paddingLeft: '1rem' }}>
          <SkillColumn
            skills={rightColumnSkills}
            visibleSkills={visibleSkills}
          />
        </div>
      </div>
    </div>
  );
};

const SkillsSection = () => {
  return (
    <div className={'skills'}>
      <div className='container'>
        <h2>Leveling up!</h2>
        <p>
          Every project, every line of code, and every problem solved has been a
          step forward in mastering my craft. Like leveling up in a game, each
          skill I’ve honed has unlocked new possibilities, allowing me to turn
          even the most ambitious digital dreams into reality. From developing
          custom CMS solutions to optimizing complex web architectures, these
          are the abilities I’ve gained on my journey so far—skills that
          continue to grow with each challenge I tackle.
        </p>
        <SplitSkillColumn title='Languages' skills={skillsData.languages} />
        <SplitSkillColumn title='Tools' skills={skillsData.tools} />
        <SplitSkillColumn title='Soft Skills' skills={skillsData.softSkills} />
      </div>
    </div>
  );
};

export default SkillsSection;
