import React from 'react';
import ContactForm from './components/ContactForm';

const Contact = () => {
  return (
    <section id='contact'>
      <ContactForm />
    </section>
  );
};

export default Contact;
