import React from 'react';
import { Element } from 'react-scroll';
import Hero from './Hero';
import CtaButtons from './components/CtaButtons';
import Footer from './components/Footer';
import About from './About';
import Skills from './Skills';
import Work from './Work';
import Contact from './Contact';
import { SpeedInsights } from '@vercel/speed-insights/react';
import { Analytics } from '@vercel/analytics/react';
import AnimatedCursor from 'react-animated-cursor';

function App() {
  return (
    <React.Fragment>
      <AnimatedCursor
        innerSize={8}
        outerSize={40}
        color='72, 62, 76'
        outerAlpha={1}
        innerScale={1}
        outerScale={1.5}
        clickables={[
          'a',
          'input[type="text"]',
          'input[type="email"]',
          'input[type="number"]',
          'input[type="submit"]',
          'input[type="image"]',
          'label[for]',
          'select',
          'textarea',
          'button',
          '.link'
        ]}
        hasBlendMode={true}
        innerStyle={{
          zIndex: '99999',
        }}
        outerStyle={{
          zIndex: '99999',
          mixBlendMode: 'exclusion',
          filter: 'invert(1)',
        }}
      />
      <header>
        <CtaButtons />
      </header>
      <main>
        <Element name='hero' as="section">
          <Hero />
        </Element>
        <Element name='about' as="section">
          <About />
        </Element>
        <Element name='skills' as="section">
          <Skills />
        </Element>
        <Element name='work' as="section">
          <Work />
        </Element>
        <Element name='contact' as="section">
          <Contact />
        </Element>
      </main>
      <Footer />
      <Analytics />
      <SpeedInsights />
    </React.Fragment>
  );
}

export default App;