import React, { useEffect, useRef } from 'react';
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { FaLaptopCode } from 'react-icons/fa6';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const employmentHistory = [
  {
    company: 'Ackama',
    position: 'Web Developer',
    date: 'August 2018 - Present',
    description: (
      <>
        <p>
          At Ackama, I transform client visions into dynamic digital
          experiences, specializing in custom WordPress, Drupal, and
          Silverstripe CMS development for high-profile government and nonprofit
          organizations.
        </p>
        <p>
          My expertise spans full-stack development using the LAMP stack,
          Docker, and Git, ensuring both scalability and security.
        </p>
        <p>
          Recently, my work on a Drupal project earned a nomination for the
          prestigious{' '}
          <a
            href='https://drupalsouth.org/2024-awards'
            style={{ color: '#f2cd51', textDecoration: 'none' }}
          >
            Splash Award at DrupalSouth 2024
          </a>
          , showcasing my dedication to crafting innovative solutions that stand
          out.
        </p>
      </>
    ),
    icon: <FaLaptopCode />,
  },
  {
    company: 'Plot Media',
    position: 'Web Developer',
    date: 'May 2018',
    description: (
      <>
        <p>
          As the sole developer at Plot Media, I had the unique opportunity to
          lead all aspects of web development. I handled everything from hosting
          setup to custom CMS builds, ensuring the highest standards of quality
          and performance for a diverse range of clients.
        </p>
        <p>
          After a brief but impactful tenure, Plot Media was acquired by Ackama,
          where I continued my journey, further expanding my skills and taking
          on more complex challenges (like learning Drupal!).
        </p>
        <p>
          My time at Plot sharpened my problem-solving abilities and cemented my
          passion for crafting digital solutions
        </p>
      </>
    ),
    icon: <FaLaptopCode />,
  },
  {
    company: 'Trout Creative Thinking™',
    position: 'Web Developer',
    date: 'Oct 2016',
    description: (
      <>
        <p>
          At Trout, I merged creativity with code, working on a diverse range of
          projects from single-page sites to full-scale CMS-based websites. My
          role involved writing clean, efficient, and scalable code for custom
          Silverstripe themes, as well as PHP, JavaScript, and responsive
          design.
        </p>
        <p>
          I handled everything from web architecture to user experience,
          ensuring our sites were visually engaging and performance-driven.
        </p>
      </>
    ),
    icon: <FaLaptopCode />,
  },
  {
    company: 'Barking Bird',
    position: 'Web Developer',
    date: 'Oct 2014',
    description: (
      <>
        <em>From Production Assistant to Full-Fledged Developer</em>
        <p>
          My journey at Barking Bird started as a Production Assistant, where I
          balanced work with studying for a Diploma in Web Development. Upon
          completing my diploma, I was promoted to Web Developer, a role in
          which I crafted websites and apps for a variety of clients.{' '}
        </p>
        <p>
          My time at Barking Bird was a pivotal moment in my career, as it was
          here that I honed my skills in HTML, CSS, JavaScript, and PHP, and
          learned the importance of collaboration and communication in a team
          setting.
        </p>
        <p>
          Working with tools like HTML5, CSS3, PHP, and jQuery, I helped launch
          W3C-compliant, responsive, and e-commerce websites. This role marked
          the first significant step in turning my self-taught web development
          passion into a professional career.
        </p>
      </>
    ),
    icon: <FaLaptopCode />,
  },
];

const EmploymentHistory = () => {
  const timelineRef = useRef(null);
  const progressRef = useRef(null);

  useEffect(() => {
    const timeline = timelineRef.current;
    const progress = progressRef.current;

    gsap.set(progress, { scaleY: 0, transformOrigin: 'top' });

    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: timeline,
        start: 'top center',
        end: 'bottom center',
        scrub: true,
        onUpdate: self => {
          gsap.to(progress, {
            scaleY: self.progress,
            duration: 0.1,
          });
        },
      },
    });

    tl.to(progress, { scaleY: 1, ease: 'none' });

    return () => {
      tl.kill();
      ScrollTrigger.getAll().forEach(trigger => trigger.kill());
    };
  }, []);

  return (
    <div className='employment-history' ref={timelineRef}>
      <div className='employment-history__heading container'>
        <div className='col'>
          <h2>The journey so far</h2>
          <p>
            Before I ever wrote a line of code, I was capturing the world
            through a different lens—literally! My journey into the digital
            world was preceded by my passion for photography, and I even worked
            as a Marketing Coordinator for Leica Camera, blending my creative
            side with strategic thinking. Photography taught me how to frame a
            story, focus on details, and communicate visually—skills that have
            transferred seamlessly into my career as a software engineer.
          </p>

          <p>
            From my early days of teaching myself web development to now
            building custom CMS websites at Ackama, my career has been a journey
            of crafting digital dreams into reality. Along the way, I’ve had the
            opportunity to work with incredible clients and teams, turning
            complex challenges into intuitive and beautifully designed
            solutions.
          </p>
        </div>
      </div>
      <div className='timeline-progress-container'>
        <div className='timeline-progress' ref={progressRef}></div>
      </div>
      <VerticalTimeline>
        {employmentHistory.map((job, index) => (
          <VerticalTimelineElement
            key={index}
            className='vertical-timeline-element--work'
            date={job.date}
            icon={<FaLaptopCode />}
          >
            <h3 className='vertical-timeline-element-title'>{job.position}</h3>
            <h4 className='vertical-timeline-element-subtitle'>
              {job.company}{' '}
              <span className='vertical-timeline-element-title__mob'>
                {job.date}
              </span>
            </h4>
            <p>{job.description}</p>
          </VerticalTimelineElement>
        ))}
      </VerticalTimeline>
    </div>
  );
};

export default EmploymentHistory;
