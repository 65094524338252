import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-scroll';

const CtaButtons = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef(null);
  const [shouldAnimate, setShouldAnimate] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);

  const toggleMenu = () => {
    if (!isMenuOpen) {
      setIsMenuOpen(true);
      requestAnimationFrame(() => {
        setShouldAnimate(true);
      });
    } else {
      setShouldAnimate(false);
      setTimeout(() => {
        setIsMenuOpen(false);
      }, 500);
    }
  };

  const handleClickOutside = event => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsMenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  const handleDownloadCV = () => {
    setIsDownloading(true);
    setTimeout(() => {
      setIsDownloading(false);
    }, 2000);
  };

  return (
    <div className='cta-buttons'>
      <button onClick={toggleMenu} className='menu-toggle'>
        menu
      </button>
      {isMenuOpen && (
        <div
          className={`menu-overlay ${shouldAnimate ? 'menu-overlay--visible' : ''}`}
          ref={menuRef}
        >
          <button onClick={toggleMenu} className='menu-close'>
            close
          </button>
          <Link
            to='hero'
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
            onClick={toggleMenu}
          >
            home
          </Link>
          <Link
            to='about'
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
            onClick={toggleMenu}
          >
            about
          </Link>
          <Link
            to='skills'
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
            onClick={toggleMenu}
          >
            skills
          </Link>
          <Link
            to='work'
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
            onClick={toggleMenu}
          >
            work
          </Link>
          <Link
            to='contact'
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
            onClick={toggleMenu}
          >
            contact
          </Link>
        </div>
      )}
      <a
        href='/cv.pdf'
        download={true}
        className='cta-button download-cv'
        onClick={handleDownloadCV}
      >
        <span>Download CV</span>
        <svg
          className={`download-icon ${isDownloading ? 'downloading' : ''}`}
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 24 24'
          width='24'
          height='24'
        >
          <path d='M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z' />
        </svg>
      </a>
    </div>
  );
};

export default CtaButtons;
