import React from 'react';

const FlowerSvg = React.forwardRef((props, ref) => {
  return (
    <svg
      className="flower-svg"
      viewBox="-5.0 -10.0 110.0 135.0"
      xmlns="http://www.w3.org/2000/svg"
      ref={ref}
    >
      <path
        d="m91.922 45.68-16.434 16.773 3.8398 23.867c0.41797 2.4258-0.59375 4.875-2.6016 6.2969-1.9102 1.3633-4.4336 1.5039-6.4844 0.36328l-20.312-11.18-20.172 11.18c-2.0508 1.1445-4.5742 1.0039-6.4844-0.36328-2.0078-1.4219-3.0195-3.8711-2.6016-6.2969l3.8789-23.684-16.469-16.953v-0.003906c-1.6953-1.7305-2.2734-4.2656-1.4961-6.5625 0.72656-2.2383 2.6523-3.8789 4.9766-4.2461l22.707-3.4531 10.133-21.562h-0.003906c1.0039-2.1914 3.1914-3.6016 5.6055-3.6016 2.4102 0 4.6016 1.4102 5.6016 3.6016l10.156 21.562 22.676 3.4531c2.3242 0.36719 4.2422 2.0039 4.9727 4.2383 0.78125 2.2969 0.20703 4.8359-1.4883 6.5703z"
        style={{ fill: '#f2cd51', strokeWidth: 0 }}
      />
    </svg>
  );
});

export default FlowerSvg;
