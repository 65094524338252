import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { FaSpinner } from 'react-icons/fa';
import { motion, AnimatePresence } from 'framer-motion';

// Validation Schema
const ContactSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  email: Yup.string().email('Invalid email').required('Required'),
  subject: Yup.string().min(2, 'Too Short!').required('Required'),
  message: Yup.string().min(10, 'Too Short!').required('Required'),
});

const ContactForm = () => {
  const [formStatus, setFormStatus] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);

  return (
    <AnimatePresence mode='wait'>
      {isSubmitted ? (
        <motion.div
          key='thank-you'
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -50 }}
          className='thank-you-message'
        >
          <h2>Thank you for your message!</h2>
          <p>I will get back to you shortly.</p>
        </motion.div>
      ) : (
        <Formik
          initialValues={{ name: '', email: '', subject: '', message: '' }}
          validationSchema={ContactSchema}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            try {
              const response = await fetch('/api/send-email', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify(values),
              });

              if (response.ok) {
                setFormStatus('Email sent successfully!');
                resetForm();
                setIsSubmitted(true);
              } else {
                throw new Error('Failed to send email');
              }
            } catch (error) {
              console.error('Error:', error);
              setFormStatus(
                "There was an issue processing your request, but your email may have been sent successfully. If you don't hear back, please try again later."
              );
            } finally {
              setSubmitting(false);
            }
          }}
        >
          {({ isSubmitting, errors, touched }) => (
            <motion.div
              key='form'
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -50 }}
            >
              <Form aria-labelledby='contactFormLabel'>
                <h2 id='contactFormLabel'>Get in touch</h2>
                {formStatus && <div className='form-status'>{formStatus}</div>}

                <div
                  className={`form-group ${errors.name && touched.name ? 'form-group-error' : ''}`}
                >
                  <label htmlFor='name'>Name</label>
                  <Field id='name' name='name' placeholder='Your Name' />
                  <ErrorMessage
                    name='name'
                    component='div'
                    className='field-error'
                  />
                </div>

                <div
                  className={`form-group ${errors.email && touched.email ? 'form-group-error' : ''}`}
                >
                  <label htmlFor='email'>Email</label>
                  <Field
                    id='email'
                    name='email'
                    type='email'
                    placeholder='Your Email'
                  />
                  <ErrorMessage
                    name='email'
                    component='div'
                    className='field-error'
                  />
                </div>

                <div
                  className={`form-group ${errors.subject && touched.subject ? 'form-group-error' : ''}`}
                >
                  <label htmlFor='subject'>Subject</label>
                  <Field id='subject' name='subject' placeholder='Subject' />
                  <ErrorMessage
                    name='subject'
                    component='div'
                    className='field-error'
                  />
                </div>

                <div
                  className={`form-group ${errors.message && touched.message ? 'form-group-error' : ''}`}
                >
                  <label htmlFor='message'>Message</label>
                  <Field
                    id='message'
                    name='message'
                    as='textarea'
                    rows='10'
                    placeholder='Your Message'
                  />
                  <ErrorMessage
                    name='message'
                    component='div'
                    className='field-error'
                  />
                </div>

                <motion.button
                  type='submit'
                  disabled={isSubmitting}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  {isSubmitting ? (
                    <motion.span
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                    >
                      <FaSpinner className='icon-spin' /> Sending...
                    </motion.span>
                  ) : (
                    'Send Message'
                  )}
                </motion.button>
              </Form>
            </motion.div>
          )}
        </Formik>
      )}
    </AnimatePresence>
  );
};

export default ContactForm;
