import React from 'react';
import react from '../img/react.png';
import vercel from '../img/vercel.png';

const Footer = () => {
  const currentYear = new Date().getFullYear(); // Get the current year

  return (
    <footer>
      <div className='container'>
        <div className='col'>
          <div className='footer-content'>
            <p>
              © {currentYear} Created by Liliana Summers with <span>♡</span> +{' '}
              <img src={react} className='react' alt={'React Logo'} /> +{' '}
              <img src={vercel} className='vercel' alt={'Vercel Logo'} />
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
