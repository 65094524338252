import React, { useRef, useEffect } from 'react';
import liliana from './img/lilianasummerscard.png';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import FlowerSvg from './components/svg/FlowerSvg';
import BiographyText from './components/BiographyText';
import ProfileCard from './components/ProfileCard';

gsap.registerPlugin(ScrollTrigger);

const About = () => {
  const daisyRef = useRef(null);

  useEffect(() => {
    ScrollTrigger.create({
      trigger: '#about',
      start: 'top bottom',
      end: 'bottom top',
      scrub: true,
      onUpdate: self => {
        const scrollProgress = self.progress;
        const rotationDegrees = scrollProgress * 360;
        gsap.to(daisyRef.current, { rotation: rotationDegrees });
      },
    });

    return () => {
      ScrollTrigger.killAll();
    };
  }, []);

  return (
    <section id='about'>
      <FlowerSvg ref={daisyRef} />

      <div className='container'>
        <div className='col heading-col'>
          <p>Olá! I'm</p>
          <h1 className='signature'>Liliana Summers</h1>
        </div>
      </div>
      <div className="container">
        <BiographyText />
        <div className="col">
          <ProfileCard imageUrl={liliana} />
        </div>
      </div>
      <div className='custom-shape-divider-bottom-1708657878'>
        <svg
          data-name='Layer 1'
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 1200 120'
          preserveAspectRatio='none'
        >
          <path
            d='M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z'
            className='shape-fill-beige'
          ></path>
        </svg>
      </div>
    </section>
  );
};

export default About;